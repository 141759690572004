import React from "react";
import "./footer.css"

import './styles/frontend-lite.min.css'; 
import "./styles/bootstrap.css";
import './styles/post-3979.css';
import './styles/classic-themes.min.css'
// import './styles/elementor-icons.min.css'
// import './styles/flaticon.css'
// import './styles/font-awesome.min.css'
// import './styles/global.css';
// import './styles/lightgallery.css'
import "./styles/post-3518.css";
import "./styles/post-6.css";
import "./styles/style.css"

function Footer() {
  return (
    <>
      {
        <footer
          id="site-footer"
          class="site-footer"
          itemscope="itemscope"
          itemtype="http://schema.org/WPFooter"
        >
          {" "}
          <div
            data-elementor-type="wp-post"
            data-elementor-id="3979"
            class="elementor elementor-3979"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-e1ad59d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="e1ad59d"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div className="elementor-container elementor-column-gap-extended">
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d2ab095 ot-flex-column-vertical"
                  data-id="d2ab095"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-6625582 elementor-widget elementor-widget-text-editor"
                      data-id="6625582"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>About Singh Technologies</p>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-fe365dc elementor-widget elementor-widget-text-editor"
                      data-id="fe365dc"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          Emerging organization’s trends more and more emphasize
                          the need for the Organization's committed focus on the
                          core business and services "Do what you do best." At
                          Singh Tech, we are committed to doing what we do best,
                          "IT SERVICES." At Singh Tech, we value the Client’s
                          Time, Budget, Project Timelines, Process in place,
                          Human Resources, Security and Privacy,
                          Confidentiality, Intellectual Property, Systems
                          infrastructure, various IT departments, and "Partner"
                          with them to deliver the Information Technology (IT)
                          services needed as per the expectations and
                          contractual agreement between both.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5991e26 ot-flex-column-vertical"
                  data-id="5991e26"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-01e65ea elementor-widget elementor-widget-heading"
                      data-id="01e65ea"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h6 className="elementor-heading-title elementor-size-default">
                          CONTACTS
                        </h6>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-ee513f6 elementor-align-right elementor-mobile-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                      data-id="ee513f6"
                      data-element_type="widget"
                      data-widget_type="icon-list.default"
                    >
                      <div className="elementor-widget-container">
                        <ul className="elementor-icon-list-items">
                          <li className="elementor-icon-list-item">
                            <a href="https://goo.gl/maps/Z56pAiKqSuKhAYED6">
                              <span className="elementor-icon-list-icon">
                                <svg
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  style={{
                                    enableBackground: "new 0 0 512 512",
                                  }}
                                  xmlSpace="preserve"
                                >
                                  {/* SVG path data */}
                                </svg>
                              </span>
                              <span className="elementor-icon-list-text">
                                205 Park Central E Holland Office Building,
                                Springfield, MO 65806, United States
                              </span>
                            </a>
                          </li>
                          <li className="elementor-icon-list-item">
                            <a href="mailto:info@thesinghtechnologies.com">
                              <span className="elementor-icon-list-icon">
                                <svg
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  style={{
                                    enableBackground: "new 0 0 512 512",
                                  }}
                                  xmlSpace="preserve"
                                >
                                  {/* SVG path data */}
                                </svg>
                              </span>
                              <span className="elementor-icon-list-text">
                                info@thesinghtechnologies.com
                              </span>
                            </a>
                          </li>
                          <li className="elementor-icon-list-item">
                            <a href="tel:+13237233472">
                              <span className="elementor-icon-list-icon">
                                <svg
                                  version="1.1"
                                  id="Capa_1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 512 512"
                                  style={{
                                    enableBackground: "new 0 0 512 512",
                                  }}
                                  xmlSpace="preserve"
                                >
                                  {/* SVG path data */}
                                </svg>
                              </span>
                              <span className="elementor-icon-list-text">
                                +1-323-723-3472
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              class="elementor-section elementor-top-section elementor-element elementor-element-be920a5 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="be920a5"
              data-element_type="section"
              data-settings='{"background_background":"classic"}'
            >
              <div class="elementor-container elementor-column-gap-extended">
                <div
                  class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5fc6f28 ot-flex-column-vertical"
                  data-id="5fc6f28"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-e829ca1 elementor-widget elementor-widget-text-editor"
                      data-id="e829ca1"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div class="elementor-widget-container">
                        <p>
                          Copyright © 2022 SinghTechnologies. All Rights
                          Reserved.
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-595c03d ot-flex-column-vertical"
                  data-id="595c03d"
                  data-element_type="column"
                >
                  <div class="elementor-widget-wrap elementor-element-populated">
                    <div
                      class="elementor-element elementor-element-3d9362e elementor-shape-square e-grid-align-right e-grid-align-mobile-center elementor-grid-0 elementor-widget elementor-widget-social-icons"
                      data-id="3d9362e"
                      data-element_type="widget"
                      data-widget_type="social-icons.default"
                    >
                      <div class="elementor-widget-container">
                        <div class="elementor-social-icons-wrapper elementor-grid">
                          <span class="elementor-grid-item">
                            <a
                              class="elementor-icon elementor-social-icon elementor-social-icon- elementor-repeater-item-9d1379a"
                              target="_blank"
                            >
                              <span class="elementor-screen-only"></span>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </footer>
      }
    </>
  );
}

export default Footer;
