import React from "react";
import { Helmet } from "react-helmet";

function Meta() {
  return (
    <div>
      <Helmet>
        <meta
          name="keyword"
          content="Buy Usdt at Low Cost, Buy Usdt in India at Low cost, Buy Usdt at ₹83.12,Buy Usdt at very low cos"
        ></meta>
      </Helmet>
    </div>
  );
}

export default Meta;