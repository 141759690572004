import React from "react";
import "./header.css";

import "./styles/frontend-lite.min.css";
import "./styles/bootstrap.css";
import "./styles/post-3979.css";
import "./styles/classic-themes.min.css";
// import './styles/elementor-icons.min.css'
// import './styles/flaticon.css'
// import './styles/font-awesome.min.css'
// import './styles/global.css';
// import './styles/lightgallery.css'
import "./styles/post-3518.css";
import "./styles/post-6.css";
import "./styles/style.css";

function Header() {
  return (
    <>
      {
        <>
          <header id="site-header" class="site-header header-transparent">
            {" "}
            <div class="header-desktop">
              <div
                data-elementor-type="wp-post"
                data-elementor-id="3518"
                class="elementor elementor-3518"
              >
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-2f6f961 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="2f6f961"
                  data-element_type="section"
                  data-settings='{"background_background":"classic"}'
                >
                  <div class="elementor-container elementor-column-gap-default">
                    <div
                      class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-694bba3 ot-column-items-center ot-flex-column-vertical"
                      data-id="694bba3"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-ccb8ec3 e-grid-align-left elementor-widget__width-auto elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons"
                          data-id="ccb8ec3"
                          data-element_type="widget"
                          data-widget_type="social-icons.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="elementor-social-icons-wrapper elementor-grid">
                              <span class="elementor-grid-item">
                                <a
                                  class="elementor-icon elementor-social-icon elementor-social-icon- elementor-repeater-item-7db2c9f"
                                  target="_blank"
                                >
                                  <span class="elementor-screen-only"></span>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-62078a0 elementor-icon-list--layout-inline elementor-widget__width-auto icon-custom elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                          data-id="62078a0"
                          data-element_type="widget"
                          data-widget_type="icon-list.default"
                        >
                          <div class="elementor-widget-container">
                            <link
                              rel="stylesheet"
                              href="https://thesinghtechnologies.com/wp-content/plugins/elementor/assets/css/widget-icon-list.min.css"
                            />
                            <ul class="elementor-icon-list-items elementor-inline-items">
                              <li class="elementor-icon-list-item elementor-inline-item">
                                <a href="https://goo.gl/maps/2RcvqnKCLFkinXDm6">
                                  <span class="elementor-icon-list-icon">
                                    <svg
                                      version="1.1"
                                      id="Capa_1"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 512 512"
                                    >
                                      <g>
                                        <g>
                                          <path
                                            d="M425.951,89.021C386.864,32.451,324.917,0,256.006,0S125.148,32.451,86.061,89.021
			c-38.895,56.284-47.876,127.541-24.072,190.496c6.367,17.192,16.488,33.895,30.01,49.547l150.378,176.634
			c3.401,3.998,8.384,6.302,13.629,6.302c5.245,0,10.228-2.303,13.629-6.302l150.336-176.586
			c13.582-15.742,23.69-32.427,30.004-49.481C473.827,216.562,464.846,145.305,425.951,89.021z M416.451,267.093
			c-4.869,13.158-12.818,26.167-23.613,38.68c-0.03,0.03-0.06,0.06-0.084,0.096L256.006,466.487L119.174,305.768
			c-10.789-12.502-18.738-25.51-23.655-38.794c-19.686-52.065-12.215-110.981,19.991-157.592
			c32.307-46.76,83.519-73.578,140.496-73.578c56.976,0,108.182,26.817,140.49,73.578
			C428.708,155.993,436.185,214.909,416.451,267.093z"
                                          />
                                        </g>
                                      </g>
                                      <g>
                                        <g>
                                          <path
                                            d="M256.006,106.219c-55.276,0-100.252,44.97-100.252,100.252s44.97,100.252,100.252,100.252s100.252-44.97,100.252-100.252
			C356.258,151.195,311.282,106.219,256.006,106.219z M256.006,270.918c-35.536,0-64.448-28.912-64.448-64.448
			c0-35.536,28.912-64.448,64.448-64.448c35.536,0,64.448,28.912,64.448,64.448S291.542,270.918,256.006,270.918z"
                                          />
                                        </g>
                                      </g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                    </svg>
                                  </span>
                                  <span class="elementor-icon-list-text">
                                    205 Park Central E Holland Office Building,
                                    Springfield, MO 65806, United States
                                  </span>
                                </a>
                              </li>
                              <li class="elementor-icon-list-item elementor-inline-item">
                                <a href="mailto:info@thesinghtechnologies.com">
                                  <span class="elementor-icon-list-icon">
                                    <svg
                                      version="1.1"
                                      id="Capa_1"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 512 512"
                                    >
                                      <g>
                                        <g>
                                          <path
                                            d="M498.826,206.613L317.294,24.881c-33.166-33.166-87.422-33.166-120.536-0.052L13.18,206.607
			C4.681,215.106,0,226.406,0,238.426V467c0,24.816,20.229,45,45,45h422c24.81,0,45-20.224,45-45V238.426
			C512,226.406,507.319,215.106,498.826,206.613z M217.919,46.094c10.776-10.776,24.929-16.163,39.083-16.163
			c14.151,0,28.303,5.387,39.073,16.157l179.713,179.911L361.827,339.96l-51.794-52.041c-14.166-14.166-33-21.967-53.033-21.967
			c-20.033,0-38.868,7.801-53.002,21.937l-52.177,51.877L36.288,225.944L217.919,46.094z M30,460.884V261.863l100.544,99.056
			L30,460.884z M51.311,482c17.556-17.456,155.204-154.31,173.869-172.867c8.5-8.5,19.8-13.18,31.82-13.18
			s23.32,4.681,31.794,13.155L460.866,482H51.311z M482,460.706l-99.011-99.482L482,262.213V460.706z"
                                          />
                                        </g>
                                      </g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                      <g></g>
                                    </svg>
                                  </span>
                                  <span class="elementor-icon-list-text">
                                    info@thesinghtechnologies.com
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-64e30b3 is-fixed elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="64e30b3"
                  data-element_type="section"
                  data-settings='{"background_background":"classic"}'
                >
                  <div class="elementor-container elementor-column-gap-extended">
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b64c420 ot-flex-column-vertical"
                      data-id="b64c420"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-76cc3b4 elementor-widget elementor-widget-ilogo"
                          data-id="76cc3b4"
                          data-element_type="widget"
                          data-widget_type="ilogo.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="the-logo">
                              <a href="https://thesinghtechnologies.com/">
                                <img
                                  src="https://thesinghtechnologies.com/wp-content/uploads/2020/06/technologies-singh-.png"
                                  alt="Singh Technologies"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2dfecdd ot-flex-column-horizontal"
                      data-id="2dfecdd"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-e5dbcd7 elementor-widget elementor-widget-imenu"
                          data-id="e5dbcd7"
                          data-element_type="widget"
                          data-widget_type="imenu.default"
                        >
                          <div class="elementor-widget-container">
                            <nav id="site-navigation" class="main-navigation ">
                              <ul id="primary-menu" class="menu">
                                <li
                                  id="menu-item-4585"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-4585"
                                >
                                  <a href="https://thesinghtechnologies.com/">
                                    Home
                                  </a>
                                </li>
                                <li
                                  id="menu-item-4582"
                                  class="menu-item menu-item-type-post_type menu-item-object-page  page_item page-item-1958 current_page_item menu-item-4582"
                                >
                                  <a
                                    href="https://thesinghtechnologies.com/index.php/about-us/"
                                    aria-current="page"
                                  >
                                    About Us
                                  </a>
                                </li>
                                <li
                                  id="menu-item-4583"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4583"
                                >
                                  <a href="https://thesinghtechnologies.com/index.php/our-services-2/">
                                    Our Services
                                  </a>
                                </li>
                                <li
                                  id="menu-item-4438"
                                  class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-4438"
                                >
                                  <a href="https://buy.thesinghtechnologies.com/">
                                  Buy USDT
                                  </a>
                                </li>
                                <li
                                  id="menu-item-4584"
                                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4584"
                                >
                                  <a href="https://thesinghtechnologies.com/index.php/contacts/">
                                    Contact. us
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div class="header-mobile">
              <div
                data-elementor-type="wp-post"
                data-elementor-id="3777"
                class="elementor elementor-3777"
              >
                <section
                  class="elementor-section elementor-top-section elementor-element elementor-element-afb0fce elementor-section-full_width is-fixed elementor-section-height-default elementor-section-height-default"
                  data-id="afb0fce"
                  data-element_type="section"
                  data-settings='{"background_background":"classic"}'
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-61110df ot-flex-column-vertical"
                      data-id="61110df"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-3c407be elementor-widget elementor-widget-ilogo"
                          data-id="3c407be"
                          data-element_type="widget"
                          data-widget_type="ilogo.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="the-logo">
                              <a href="https://thesinghtechnologies.com/">
                                <img
                                  src="https://thesinghtechnologies.com/wp-content/uploads/2020/06/technologies-singh-.png"
                                  alt="Singh Technologies"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b46e93d ot-flex-column-horizontal"
                      data-id="b46e93d"
                      data-element_type="column"
                    >
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div
                          class="elementor-element elementor-element-6d35597 elementor-widget elementor-widget-isearch"
                          data-id="6d35597"
                          data-element_type="widget"
                          data-widget_type="isearch.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="octf-search octf-cta-header">
                              <div class="toggle_search octf-cta-icons">
                                <i class="ot-flaticon-search"></i>
                              </div>

                              <div class="h-search-form-field">
                                <div class="h-search-form-inner">
                                  <form
                                    role="search"
                                    method="get"
                                    class="search-form"
                                    action="https://thesinghtechnologies.com/"
                                  >
                                    <label>
                                      <span class="screen-reader-text">
                                        Search for:
                                      </span>
                                      <input
                                        type="search"
                                        class="search-field"
                                        placeholder="SEARCH..."
                                        value=""
                                        name="s"
                                      />
                                    </label>
                                    <button type="submit" class="search-submit">
                                      <i class="ot-flaticon-search"></i>
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="elementor-element elementor-element-6e322cc elementor-widget elementor-widget-imenu_mobile"
                          data-id="6e322cc"
                          data-element_type="widget"
                          data-widget_type="imenu_mobile.default"
                        >
                          <div class="elementor-widget-container">
                            <div class="octf-menu-mobile octf-cta-header">
                              <div id="mmenu-toggle" class="mmenu-toggle">
                                <button>
                                  <i class="ot-flaticon-menu"></i>
                                </button>
                              </div>
                              <div class="site-overlay mmenu-overlay"></div>
                              <div
                                id="mmenu-wrapper"
                                class="mmenu-wrapper on-right"
                              >
                                <div class="mmenu-inner">
                                  <a class="mmenu-close" href="#">
                                    <i class="ot-flaticon-right-arrow"></i>
                                  </a>
                                  <div class="mobile-nav">
                                    <ul
                                      id="menu-menu-one-page"
                                      class="mobile_mainmenu none-style"
                                    >
                                      <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-4585">
                                        <a href="https://thesinghtechnologies.com/">
                                          Home
                                        </a>
                                      </li>
                                      <li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-1958 current_page_item menu-item-4582">
                                        <a
                                          href="https://thesinghtechnologies.com/index.php/about-us/"
                                          aria-current="page"
                                        >
                                          About Us
                                        </a>
                                      </li>
                                      <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4583">
                                        <a href="https://thesinghtechnologies.com/index.php/our-services-2/">
                                          Our Services
                                        </a>
                                      </li>
                                      <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-4438">
                                        <a href="https://wa.me/c/14178940226">
                                          Our Portfolio
                                        </a>
                                      </li>
                                      <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4584">
                                        <a href="https://thesinghtechnologies.com/index.php/contacts/">
                                          Contact. us
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </header>
        </>
      }
    </>
  );
}

export default Header;
