import React, { useEffect, useState } from "react";
import axios from "axios";
import "./transactionModal.css";
import "./exchangeContainer.css";
import usdt from "../assets/usdterc20.svg";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import Loader from "./Loader";
import correct from "../assets/correct.png";
import Faq from "./Faq";
import Meta from "./Meta";

function TransactionModal(props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [successData, setSuccessData] = useState("");
  const [youWantValue, setYouWantValue] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [transaction, setTransaction] = useState("");
  const [inr,setInr] = useState("")
  const [redirectURL, setRedirectURL] = useState(""); 


  // Access the query string parameters
  const OrderId = searchParams.get("orderid");
  // const youWantValue = searchParams.get("usdtamt");
  // const walletAddress = searchParams.get("walletaddress");

  const getdata = (OrderId) => {
    // Define the API URL
    const apiUrl = `https://buy.thesinghtechnologies.com/payonline/getorderdetails.php?orderid=${OrderId}`;

    // Make the API call
    axios
      .get(apiUrl)
      .then((response) => {
        // Check if the API response is successful
        if (response.status === 200) {
          const apiResponse = response.data;
          setYouWantValue(apiResponse.usdtamt);
          setWalletAddress(apiResponse.address);
          setOrderStatus(apiResponse.status);
          setInr(apiResponse.inr)

          if (apiResponse.status === "success") {
            setTransaction(apiResponse.transactionid);
          } else {
            setTransaction("pending");
          }

          // console.log("You Want Value (USDT Amount):", youWantValue);
          // console.log("Wallet Address:", walletAddress);

          // Now you can use these values in your component
        } else {
          console.error(
            "API request failed with status code:",
            response.status
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching API data:", error);
      });
  };

  const updatestatus = (hash) => {
    const updateStatusUrl =
      "https://buy.thesinghtechnologies.com/payonline/statusupdate.php";

    // Define the data to be sent in the request body
    const updateData = {
      orderid: OrderId, // Replace with the actual order ID you want to update
      transactionid: hash,
    };
    // Make the POST request to update the status
    axios
      .post(updateStatusUrl, updateData)
      .then((response) => {
        if (response.status === 200) {
          console.log("Status updated successfully!");
        } else {
          console.error(
            "Status update failed with status code:",
            response.status
          );
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const sendUSDT = () => {
    // Define the request payload

    setIsLoading(true);
    const requestData = {
      toAddress: walletAddress, // Get the wallet address from state
      amount: youWantValue, // Get the youPayValue from state
    };
    console.log(requestData);

    // Make the HTTP POST request
    axios
      .post("https://astro-seer.com/usdt/send", requestData)
      .then((response) => {
        // Handle the response, e.g., show a success message
        console.log("Transaction successful:", response.data);
        setIsLoading(false); // Set loading state to false after API call
        // openSuccessModal(response.data); // Open the success modal
        setSuccessData(response.data);
        if (response.data.status === "success") {
          updatestatus(response.data.hash);
        }
      })
      .catch((error) => {
        // Handle any errors, e.g., show an error message
        console.error("Transaction failed:", error);
        setIsLoading(false); // Set loading state to false after API call
        // openSuccessModal(null); // Open the success modal
      });
  };

  useEffect(() => {
    // Call sendUSDT once when the component mounts

    if (orderStatus === "paid") {
      sendUSDT();
    } else {
      return;
    }
  }, [orderStatus]); // An empty dependency array ensures this effect runs only once

  useEffect(() => {
    getdata(OrderId);
  }, []);

  const handleRedirect = () => {
    // Construct the redirect URL based on the current values



    const redirect = `https://buy.thesinghtechnologies.com/payonline/?amt=${youWantValue}&inr=${inr}&addess=${walletAddress}`;

    // Set the redirect URL in state
    setRedirectURL(redirect);

    // Perform the redirect
    window.location.href = redirect;
  };


  return (
    <>
      <Header />
      <Meta/>
      <div className="transaction-conatiner">
        <div className="transaction-layout">
          <div className="exchange-info-block">
            <div className="exchange-info">
              OrderId:
              <span>{OrderId}</span>
            </div>
          </div>
          <div className="transaction-status-container">
            <div className="top-block">
              <div className="header">
                <h1 className="title">
                  {orderStatus === "success"
                    ? "USDT Sended Already"
                    : orderStatus === "paid"
                    ? "Sending USDT"
                    : orderStatus === "pending"
                    ? "Payment Pending"
                    : "Loading Details "}{" "}
                </h1>
              </div>
              {orderStatus === "pending" ? (
                <div className="walltet-section">
                  <div className="divider"></div>
                  <div className="wallet-layout">
                    <button
                      class="buy-usdt-button"
                      fdprocessedid="g9u3rk"
                      onClick={handleRedirect}
                      // disabled={isLoading || successData.status === "success"}
                    >
                      <span>{"Pay Now"}</span>
                    </button>
                  </div>
                </div>
              ) : null}

              <div className="info-row">
                <div className="info-row-label">Send USDT:</div>
                <div className="info-container">
                  <div className="info-wrapper">
                    <div className="crypto-icon">
                      <img src={usdt} className="styled-icon" alt="usdt-icon" />
                    </div>
                    <div className="amount-text">{youWantValue}</div>
                    <div className="usdt-name">USDT</div>
                  </div>
                </div>
              </div>
              <div className="info-row">
                <div className="info-row-label">Recepient Address:</div>
                <div className="info-container">
                  <div className="info-wrapper">
                    {/* <div className="crypto-icon">
                    <img
                      src={usdt}
                      class="styled-icon"
                      alt= "usdt-icon"
                    />
                  </div> */}
                    {/* <div className="amount-text">100</div> */}
                    <div className="usdt-name">{walletAddress}</div>
                  </div>
                </div>
              </div>
              {!isLoading ? (
                <div className="info-row">
                  <div className="info-row-label">Transaction Id:</div>
                  <div className="info-container">
                    <div className="info-wrapper">
                      {/* <div className="crypto-icon">
                    <img
                      src={usdt}
                      class="styled-icon"
                      alt= "usdt-icon"
                    />
                  </div> */}
                      {/* <div className="amount-text">100</div> */}
                      <div className="usdt-name">
                        <a
                          href={`${successData.hash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {successData
                            ? successData.hash
                            : orderStatus === "success"
                            ? transaction
                            : "pending"}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
            <div className="bottom-block">
              <div className="divider"></div>
              <div className="statuses">
                <div
                  className={`status-block ${isLoading ? "" : "fade"} ${
                    successData.status === "success"
                      ? "dim"
                      : orderStatus === "success"
                      ? "dim"
                      : ""
                  }`}
                >
                  <div class="flare-container">
                    <div className={`flare `}></div>
                  </div>
                  {successData && successData.status === "success" ? (
                    <img src={correct} class="status-icon" alt="status" />
                  ) : orderStatus === "success" ? (
                    <img src={correct} class="status-icon" alt="status" />
                  ) : (
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuOTQzIDEyLjk3M2ExLjEzOCAxLjEzOCAwIDEwMC0yLjI3NiAxLjEzOCAxLjEzOCAwIDAwMCAyLjI3NnptNC4wNzYgMGExLjEzOCAxLjEzOCAwIDEwMC0yLjI3NiAxLjEzOCAxLjEzOCAwIDAwMCAyLjI3NnptNC4wNzYgMGExLjEzOCAxLjEzOCAwIDEwMC0yLjI3NiAxLjEzOCAxLjEzOCAwIDAwMCAyLjI3NnoiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNOS45ODggMkM0LjQ4NiAyIDAgNi40OSAwIDEyczQuNDg2IDEwIDkuOTg4IDEwQzE1LjQ5MSAyMiAyMCAxNy41MzIgMjAgMTIgMjAgNi40OSAxNS41MTQgMiA5Ljk4OCAyem0wIDE4LjYzNGMtNC43NCAwLTguNi0zLjg2Ni04LjYtOC42MSAwLTQuNzQ2IDMuODYtOC42MTIgOC42LTguNjEyczguNjAyIDMuODY2IDguNjAyIDguNjExYy4wMjMgNC43NDUtMy44MzkgOC42MTEtOC42MDIgOC42MTF6IiBmaWxsPSIjZmZmIi8+PC9zdmc+"
                      class="status-icon"
                      alt="status"
                    />
                  )}
                  {/* <img
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUuOTQzIDEyLjk3M2ExLjEzOCAxLjEzOCAwIDEwMC0yLjI3NiAxLjEzOCAxLjEzOCAwIDAwMCAyLjI3NnptNC4wNzYgMGExLjEzOCAxLjEzOCAwIDEwMC0yLjI3NiAxLjEzOCAxLjEzOCAwIDAwMCAyLjI3NnptNC4wNzYgMGExLjEzOCAxLjEzOCAwIDEwMC0yLjI3NiAxLjEzOCAxLjEzOCAwIDAwMCAyLjI3NnoiIGZpbGw9IiNmZmYiLz48cGF0aCBkPSJNOS45ODggMkM0LjQ4NiAyIDAgNi40OSAwIDEyczQuNDg2IDEwIDkuOTg4IDEwQzE1LjQ5MSAyMiAyMCAxNy41MzIgMjAgMTIgMjAgNi40OSAxNS41MTQgMiA5Ljk4OCAyem0wIDE4LjYzNGMtNC43NCAwLTguNi0zLjg2Ni04LjYtOC42MSAwLTQuNzQ2IDMuODYtOC42MTIgOC42LTguNjEyczguNjAyIDMuODY2IDguNjAyIDguNjExYy4wMjMgNC43NDUtMy44MzkgOC42MTEtOC42MDIgOC42MTF6IiBmaWxsPSIjZmZmIi8+PC9zdmc+"
                    class="status-icon"
                    alt="status"
                  /> */}
                  <div class="status-text status-send-mobile">
                    <div>Status:</div>Sending USDT
                  </div>
                </div>
                <div
                  className={`status-block second-block ${
                    isLoading ? "fade" : ""
                  } ${
                    successData.status === "success"
                      ? "dim"
                      : orderStatus === "success"
                      ? "dim"
                      : ""
                  }`}
                >
                  <div class="flare-container">
                    <div
                      className={`flare ${
                        successData === "" ? "fade" : isLoading ? "fade" : ""
                      } `}
                    ></div>
                  </div>
                  {successData && successData.status === "success" ? (
                    <img src={correct} class="status-icon" alt="status" />
                  ) : orderStatus === "success" ? (
                    <img src={correct} class="status-icon" alt="status" />
                  ) : (
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2LjgxNyA2LjEzNWEuNTQ3LjU0NyAwIDAwLS41NTItLjFMMi4zNSAxMS4xODhhLjU0Ny41NDcgMCAwMC0uMDA0IDEuMDE5bDYuMDg4IDIuMzYgMi4zNiA2LjA4N2EuNTQ3LjU0NyAwIDAwMS4wMTgtLjAwNGw1LjE1My0xMy45MTVhLjU0Ny41NDcgMCAwMC0uMTQ4LS42em0tMS4yODUgMS4zMzNsLTQuMjQ0IDExLjQ1LTEuOTI0LTQuOTY4YS41NDYuNTQ2IDAgMDAtLjMxNC0uMzE0bC00Ljk3LTEuOTI0IDExLjQ1MS00LjI0NHoiIGZpbGw9IiNmZmYiLz48L3N2Zz4="
                      class="status-icon"
                      alt="status"
                    ></img>
                  )}
                  <div class="status-text status-send-mobile">
                    <div>Status:</div>
                    {successData === ""
                      ? "Sent Successfully"
                      : isLoading
                      ? "USDT Sent Successfully"
                      : successData.status === "success"
                      ? "USDT Sent Successfully"
                      : "Transaction Failed"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq-div">
          <Faq />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TransactionModal;
