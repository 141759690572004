import logo from "./logo.svg";
import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ExchangeInterface from "./Components/ExchangeInterface";
import TransactionModal from "./Components/TransactionModal";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ExchangeInterface />} />
          <Route exact path="/sendusdt" element={<TransactionModal />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
