import React from "react";
import "./loader.css"; // Create a CSS file for your loader styles

function Loader() {
  return (
    <>
      <div className="loader-container">
        <div className="loader"></div>
      </div>
      <div className="loader-container loader-text">Sending USDT</div>
    </>
  );
}

export default Loader;
