import React from 'react';
import { useState } from 'react';
import "./exchangeContainer.css";



const FAQData = [
  {
    question: "What is the recipient's address and where do I get it?",
    answer:
      "You can find the cryptocurrency address in the crypto wallet that you use to keep your coins and tokens. It contains letters and numbers and looks like an alphanumeric string. Cryptocurrencies are based on different blockchains and have their own unique address formats. It’s necessary to provide us with the crypto wallet address, and we’ll send your coins there.",
  },
  {
    question: "Why is my recipient address shown as invalid?",
    answer:
      "There may be various reasons, including: the spelling of the address, extra spaces or incorrect blockchain",
  },
  {
    question: "What can i do if my transaction will failed?",
    answer: "You can contact us on whatsapp : +1 (323) 723-3472",
  },
];

function Faq() {
  
  const [expandedItems, setExpandedItems] = useState(
    Array(FAQData.length).fill(false)
  );

  const toggleItem = (index) => {
    const updatedExpandedItems = [...expandedItems];
    updatedExpandedItems[index] = !expandedItems[index];
    setExpandedItems(updatedExpandedItems);
  };


  return (
    <div className="faq-block">
            <div color="#141A2E" class="faq-heading">
              Have any questions?
            </div>
            {FAQData.map((faq, index) => (
              <div className="faq-question-wrapper">
                <div
                  key={index}
                  className={`styles-wrapper ${
                    expandedItems[index] ? "expanded" : ""
                  }`}
                >
                  <div className="faq-head">
                    <div className="faq-question">{faq.question}</div>
                    <div
                      class="faq-arrow"
                      onClick={() => toggleItem(index)}
                    ></div>
                  </div>
                  <div
                    className={`faq-body ${
                      expandedItems[index] ? "expanded" : ""
                    }`}
                  >
                    <p>{faq.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
  )
}

export default Faq