import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal"; // Import the Modal component
import inr from "../assets/inr.svg";
import usdt from "../assets/usdterc20.svg";
import TransactionModal from "./TransactionModal";
import "./exchangeContainer.css";
import Header from "./Header";
import Footer from "./Footer";
import Faq from "./Faq";
import Meta from "./Meta";

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
    backgroundColor: "rgb(248,248,248)",
  },
};

function ExchangeInterface() {
  // State to manage the value of the "You want" input field
  const [youWantValue, setYouWantValue] = useState(100);

  // State to store the random rate
  const [ourRate, setOurRate] = useState(generateRandomRate());

  const [isInputClicked, setIsInputClicked] = useState(false);
  const [isPhoneInputClicked, setIsPhoneInputClicked] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  // const [expandedItems, setExpandedItems] = useState(
  //   Array(FAQData.length).fill(false)
  // );

  const [OrderId, setOrderId] = useState("");

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // State to control the success modal
  const [isAddressValid, setIsAddressValid] = useState(false); // State to track address validity
  const [isLoading, setIsLoading] = useState(false);
  const [successData, setSuccessData] = useState(null); // State to store success data

  const [isInputLessThan50, setIsInputLessThan50] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);

  const inputRef = useRef(null);
  const ypuPayRef = useRef(null);

  const handleInputClick = () => {
    setIsInputClicked(true);
  };

  const handlePhoenInputClick = () => {
    setIsPhoneInputClicked(true);
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsInputClicked(false);
    }
  };

  const handleYouWantValue = (e) => {
    const value = e.target.value;
    setYouWantValue(value);

    if (parseFloat(value) < 100) {
      setIsInputLessThan50(true);
      ypuPayRef.current.style.display = "block";
    } else {
      setIsInputLessThan50(false);
      ypuPayRef.current.style.display = "none";
    }
  };

  const handleWalletAddressChange = (e) => {
    const value = e.target.value;
    setWalletAddress(value);
    setIsAddressValid(true); 
    // Check if the input starts with '0x'
    if (value.startsWith("0x") || value.startsWith("T")) {
      // If it starts with '0x', remove any red border
      inputRef.current.style.borderColor = "";
      setIsAddressValid(true); // Address is valid
    } else {
      // If it doesn't start with '0x', set a red border
      inputRef.current.style.borderColor = "red";
      setIsAddressValid(false); // Address is not valid
    }
  };

  // Function to generate a random rate between 80.00 and 85.00
  function generateRandomRate() {
    return (Math.random() * (86.0 - 85.0) + 85.0).toFixed(2);
  }

  // Effect to update the random rate on component mount (refresh)
  useEffect(() => {
    setOurRate(generateRandomRate());
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Calculate "You Pay" based on "You Want" and "Our Rate"
  const youPayValue = (parseFloat(youWantValue) * parseFloat(ourRate)).toFixed(
    2
  );

  // Function to handle the success modal
  const openSuccessModal = (data) => {
    setSuccessData(data); // Set the success data
    setIsSuccessModalOpen(true);
  };

  const closeSuccessModal = () => {
    setSuccessData(null);
    setIsSuccessModalOpen(false);
  };

  const handleRedirect = () => {
    // Construct the redirect URL based on the current values
    const amt = youWantValue; // Calculate the amount
    const rate = ourRate; // Get the rate
    const inr = youPayValue; // Format INR value
    const phone = phoneNumber;

    const redirect = `https://buy.thesinghtechnologies.com/payonline/?amt=${amt}&inr=${inr}&address=${walletAddress}&mobile=${phone}`;

    // Set the redirect URL in state
    setRedirectURL(redirect);

    // Perform the redirect
    window.location.href = redirect;
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Remove any non-numeric characters (e.g., spaces, dashes)
    const numericValue = value.replace(/\D/g, "");
    // Update the phone number state with the numeric value
    setPhoneNumber(numericValue);
    // Check if the numeric value has exactly 10 digits
    if (numericValue.length === 10) {
      // Phone number is valid
      setIsValidPhoneNumber(true);
    } else {
      // Phone number is not valid
      setIsValidPhoneNumber(false);
    }
  };

  const sendUSDT = () => {
    // makePayment()
    // setIsLoading(true);
    // openSuccessModal(null);
    // // Generate a unique order ID based on current timestamp
    // const currentTimestamp = Date.now();
    // const generatedOrderId = `ORDER${currentTimestamp}CRY`;
    // setOrderId(generatedOrderId);
    // // Define the request payload
    // const requestData = {
    //   toAddress: walletAddress, // Get the wallet address from state
    //   amount: youWantValue, // Get the youPayValue from state
    // };
    // // Make the HTTP POST request
    // axios
    //   .post("http://74.208.189.177/usdt/send", requestData)
    //   .then((response) => {
    //     // Handle the response, e.g., show a success message
    //     console.log("Transaction successful:", response.data);
    //     setIsLoading(false); // Set loading state to false after API call
    //     openSuccessModal(response.data); // Open the success modal
    //   })
    //   .catch((error) => {
    //     // Handle any errors, e.g., show an error message
    //     console.error("Transaction failed:", error);
    //     setIsLoading(false); // Set loading state to false after API call
    //     openSuccessModal(null); // Open the success modal
    //   });
  };

  return (
    <>
      <Header />
      <Meta />
      <body>
        <div className="exchange-container">
          <div className="exchnage-layout">
            <div className="exchnage-form-container">
              <h1 className="exchange-title">Buy USDT</h1>

              <div className="exchnage-rate-section">
                <div className="exchange-crypto">
                  <div class="styles-inputgroup">
                    <div class="styles-input-label">
                      You want
                      {/* <div class="styles-estimate-container">≈$300.00</div> */}
                    </div>
                    <div class="styles-input-container">
                      <input
                        type="text"
                        inputmode="decimal"
                        value={youWantValue}
                        onChange={handleYouWantValue}
                        className={`styles-input-rupee ${
                          isInputLessThan50 ? "input-error" : ""
                        }`}
                        fdprocessedid="v4mtld"
                      />
                      {/* <div class="styles__EstimateContainer-sc-6affsp-1 iMObvM">
                     {youWantValue}
                    </div> */}
                    </div>
                  </div>
                  <div class="styles-crypto">
                    <div class="styles-crypto-icon">
                      <img src={usdt} class="styles-usdt-icon" alt="usdt" />
                    </div>
                    <span class="styles-usdt-name">USDT</span>
                    
                    {/* <div class="styles__DropdownArrow-sc-6affsp-8 dzfVdM"></div> */}
                  </div>
                </div>
                <div ref={ypuPayRef} className="error">
                  Buy Minimum 100 USDT{" "}
                </div>
                <div className="transaction-rate">
                  <div className="our-rate-section">Our Rate = {ourRate}</div>
                </div>
                <div className="exchange-crypto">
                  <div class="styles-inputgroup">
                    <div class="styles-input-label">
                      You Pay
                      {/* <div class="styles-estimate-container">{youPayValue}</div> */}
                    </div>
                    <div class="styles-input-container">
                      <input
                        type="text"
                        inputmode="decimal"
                        class="styles-input-rupee"
                        value={youPayValue}
                        disabled
                        fdprocessedid="v4mtld"
                      />
                      {/* <div class="styles__EstimateContainer-sc-6affsp-1 iMObvM">
                      ≈$300.00
                    </div> */}
                    </div>
                  </div>
                  <div class="styles-crypto">
                    <div class="styles-crypto-icon">
                      <img src={inr} class="styles-usdt-icon" alt="usdt" />
                    </div>
                    <span class="styles-usdt-name">INR</span>

                    {/* <div class="styles__DropdownArrow-sc-6affsp-8 dzfVdM"></div> */}
                  </div>
                </div>
              </div>
              <div className="walltet-section">
                <div className="divider"></div>
                <div className="wallet-layout">
                  <div className="wallet-title-conatiner">
                    <h2 className="styles-wallet-title">
                      Enter the wallet address
                    </h2>
                  </div>
                  <div
                    className={`styles-inputgroup-wallet ${
                      isInputClicked ? "clicked" : ""
                    }`}
                    ref={inputRef}
                  >
                    <div
                      className={`styles-input-label-wallet ${
                        isInputClicked ? "clicked" : ""
                      }  ${isAddressValid ? "valid-address" : " "}`}
                      ref={inputRef}
                    >
                      The recipient’s ERC20 / BEP20 / TRC20 Address
                    </div>
                    <input
                      id="address-input"
                      class="styles_input-wallet-address"
                      value={walletAddress}
                      fdprocessedid="yj2za"
                      onClick={handleInputClick}
                      onChange={handleWalletAddressChange}
                    />
                  </div>

                  <div class="styles-warning-mesage">
                    Please note USDT will be sent on the Automactic Address network.
                  </div>

                  <div className="wallet-title-conatiner">
                    <h2 className="styles-wallet-title">Whatsapp Number</h2>
                  </div>
                  <div
                    className={`styles-inputgroup-wallet ${
                      isPhoneInputClicked ? "clicked" : ""
                    }`}
                    ref={inputRef}
                  >
                    <div
                      className={`styles-input-label-wallet ${
                        isPhoneInputClicked ? "clicked" : ""
                      }  ${isValidPhoneNumber ? "valid-address" : " "}`}
                      ref={inputRef}
                    >
                      Whatsapp No/ Mobile No
                    </div>
                    <input
                      id="address-input"
                      class="styles_input-wallet-address"
                      value={phoneNumber}
                      fdprocessedid="yj2za"
                      onClick={handlePhoenInputClick}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                  <button
                    class="buy-usdt-button"
                    fdprocessedid="g9u3rk"
                    onClick={handleRedirect}
                    disabled={
                      !isAddressValid ||
                      isLoading ||
                      isInputLessThan50 ||
                      youWantValue === "" ||
                      !isValidPhoneNumber
                    }
                  >
                    <span>{isLoading ? "Loading..." : "Buy USDT"}</span>
                  </button>
                </div>
              </div>
            </div>
            <Faq />
          </div>
        </div>

        <Modal
          isOpen={isSuccessModalOpen}
          onRequestClose={closeSuccessModal}
          style={customStyles}
          contentLabel="Success Modal"
        >
          {/* <div className="success-modal-content">
          {isLoading ? (
            <h2>Processing...</h2>
          ) : successData ? (
            <>
              <h2>Transaction Successful!</h2>
              <p>Your transaction was successful.</p>
              <pre>{JSON.stringify(successData, null, 2)}</pre>
            </>
          ) : (
            <>
              <h2>Transaction Failed</h2>
              <p>There was an error processing your transaction.</p>
            </>
          )}
          <button onClick={closeSuccessModal}>Close</button>
        </div> */}
          <TransactionModal
            youWantValue={youWantValue}
            walletAddress={walletAddress}
            isLoading={isLoading}
            successData={successData}
            OrderId={OrderId}
          />
        </Modal>
      </body>
      <Footer />
    </>
  );
}

export default ExchangeInterface;
